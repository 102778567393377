/*Default background spinner*/
.spinner-overlay {
    -moz-transition: width 1s linear, height 1s linear, top 1s linear, left 1s linear;
    -o-transition: width 1s linear, height 1s linear, top 1s linear, left 1s linear;
    -webkit-transition: width 1s linear, height 1s linear, top 1s linear, left 1s linear;
    -ms-transition: width 1s linear, height 1s linear, top 1s linear, left 1s linear;
    transition: width 1s linear, height 1s linear, top 1s linear, left 1s linear;
    z-index: 1000;
    width: 100%;
    min-height: 100%;
    height: auto !important;
    visibility: visible;
    //position: fixed;
    position: absolute;
    top: 0;
    opacity: 0.75;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
  }
  
  .effect-1,
  .effect-2,
  .effect-3 {
    border-left: 3px solid #0e7a42 !important;
  }

  .fallback-spinner.app-loader {
    z-index: 9000;
  }
