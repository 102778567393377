/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.nav-item {
  &.active {
    a {
      svg {
        fill: white;
      }
    }
  }
  .menu-item {
    text-transform: capitalize;
  }
}

.vertical-layout {
  &.vertical-menu-modern {
    .main-menu {
      //background: #140F26;
      &.menu-light .navigation {
        background: transparent;
      }
      .navigation {
        > li > a svg,
        > li > a i {
          //color: yellow;
          //color: darken($theme-color, 4.9%);
          //fill: yellow;//$body-color;
          height: 25px !important;
          width: 25px !important;
        }
      }
      /*.navigation {
        > li > a svg {
          fill: yellow;//$body-color;
        }
      }*/
    }
  }
}

/*
* styled-components for dynamic table
*/
body.dark-layout {
  .fhCFWO, .hLGqlg, .hCBnvI, .bhoXfZ {
    background-color: transparent !important;
    color: white;
  }
  .eCkmfb {
    background-color: #30585C !important;
    color: white;
  }
  .gPLhoV {
    &:disabled {
      svg {
        fill: gray;
      }
    }
    svg {
      fill: white;
    }
  }
}

.imu-status-online {
  background-color: #83A318;
  padding: 2px;
  color: white;
}
.imu-status-offline {
  background-color: #FC5C47;
  padding: 2px;
  color: white;
}
.imu-status-rebooting {
  background-color: #444;
  padding: 2px;
  color: white;
  font-size: 11px;
}

/* https://color.adobe.com/es/create/color-wheel */
/* Tonos */
.imu-row-green-color {
  background-color: #97BD1C !important;
  color: black;
  &:hover {
    background-color: #83A318 !important;
  }
}
.imu-row-red-color {
  background-color: #FC7260 !important;
  color: black;
  &:hover {
    background-color: #E36756 !important;
  }
}
.imu-row-yellow-color {
  background-color: #F0D83C !important;
  color: black;
  &:hover {
    background-color: #D6C136 !important;
  }
}
.imu-row-orange-color {
  background-color: #d69336 !important;
  color: black;
  &:hover {
    background-color: #BD822F !important;
  }
}


.react-dataTable {

  .rdt_TableRow {
    &:hover {
      border: 1px solid black;
      outline: initial !important;
    }
  }

  .rdt_TableCell {
    font-weight: bold;
    min-height: 53px;
    height: initial !important;
    
    .action-icon {
      padding-right: 10px;
    }
    .action-dropdown-icon {
      padding-left: 10px;
    }
    .text-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

body.dark-layout {
  .imu-status-online {
    background-color: #6E8A15;
    padding: 2px;
    color: white;
  }
  .imu-status-offline {
    background-color: #7D2D23;
    padding: 2px;
    color: white;
  }
  
  .react-dataTable {
    .imu-row-green-color {
      background-color: #87a919 !important;
    }
    .imu-row-red-color {
      background-color: #e15846 !important;
    }
    .imu-row-yellow-color {
      background-color: #d1ba29 !important;
    }

    .rdt_TableCell {
      font-weight: bold;
      color: #e7e7e7 !important;
    }

    .column-action svg {
      stroke: #283045 !important;
    }

    .btn-link {
      color: #283045 !important;
      &:hover {
        color: #c2c5c7 !important;
      }
      &:hover:not(:disabled) {
        background-color: initial !important;
      }
    }

    /* button:hover:not(:disabled) {
      background-color: initial !important;
    } */
  }
  
}

.imu-green-color {
  color: #97BD1C !important;
}
.imu-red-color {
  color: #FC7260 !important;
}
.imu-yellow-color {
  color: #F0D83C !important;
}

.imu-door-image {
  width: 40%;
  display: block;
  margin: auto;
}

.imu-door-label {
  margin-right: 30px;
  margin-top: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.rdt_Table {
  min-height: 210px
}

@media (max-width: 470px) {
  .auth-wrapper {
    .auth-bg {
      background-color: #f5f5f5 !important;
    }
  }
  
  /*.dark-layout {
    .auth-wrapper .auth-bg {
      background-color: $theme-dark-card-bg;
    }
  }*/
}

@import "css-circle/css/circle";
@import "custom-circle";
@import "custom-badge";
@import 'spinner';
//@import "../../../node_modules/flatpickr/dist/themes/dark.css";
